@import '~pretty-checkbox/src/pretty-checkbox.scss';

@font-face {
  font-family: 'Apercu';
  src: url('/fonts/Apercu-BoldItalic.eot');
  src: url('/fonts/Apercu-BoldItalic.woff2') format('woff2'),
    url('/fonts/Apercu-BoldItalic.woff') format('woff'),
    url('/fonts/Apercu-BoldItalic.ttf') format('truetype'),
    url('/fonts/Apercu-BoldItalic.svg') format('svg'),
    url('/fonts/Apercu-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Apercu';
  src: url('/fonts/Apercu-Bold.eot');
  src: url('/fonts/Apercu-Bold.woff2') format('woff2'),
    url('/fonts/Apercu-Bold.woff') format('woff'),
    url('/fonts/Apercu-Bold.ttf') format('truetype'),
    url('/fonts/Apercu-Bold.svg') format('svg'),
    url('/fonts/Apercu-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('/fonts/Apercu-Italic.eot');
  src: url('/fonts/Apercu-Italic.woff2') format('woff2'),
    url('/fonts/Apercu-Italic.woff') format('woff'),
    url('/fonts/Apercu-Italic.ttf') format('truetype'),
    url('/fonts/Apercu-Italic.svg') format('svg'),
    url('/fonts/Apercu-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Apercu';
  src: url('/fonts/Apercu-Regular.eot');
  src: url('/fonts/Apercu-Regular.woff2') format('woff2'),
    url('/fonts/Apercu-Regular.woff') format('woff'),
    url('/fonts/Apercu-Regular.ttf') format('truetype'),
    url('/fonts/Apercu-Regular.svg') format('svg'),
    url('/fonts/Apercu-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('/fonts/Apercu-Medium.eot');
  src: url('/fonts/Apercu-Medium.woff2') format('woff2'),
    url('/fonts/Apercu-Medium.woff') format('woff'),
    url('/fonts/Apercu-Medium.ttf') format('truetype'),
    url('/fonts/Apercu-Medium.svg') format('svg'),
    url('/fonts/Apercu-Medium.otf') format('opentype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('/fonts/Apercu-MediumItalic.eot');
  src: url('/fonts/Apercu-MediumItalic.woff2') format('woff2'),
    url('/fonts/Apercu-MediumItalic.woff') format('woff'),
    url('/fonts/Apercu-MediumItalic.ttf') format('truetype'),
    url('/fonts/Apercu-MediumItalic.svg') format('svg'),
    url('/fonts/Apercu-MediumItalic.otf') format('opentype');
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: 'Apercu';
  src: url('/fonts/Apercu-Light.eot');
  src: url('/fonts/Apercu-Light.woff2') format('woff2'),
    url('/fonts/Apercu-Light.woff') format('woff'),
    url('/fonts/Apercu-Light.ttf') format('truetype'),
    url('/fonts/Apercu-Light.svg') format('svg'),
    url('/fonts/Apercu-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: url('/fonts/Apercu-LightItalic.eot');
  src: url('/fonts/Apercu-LightItalic.woff2') format('woff2'),
    url('/fonts/Apercu-LightItalic.woff') format('woff'),
    url('/fonts/Apercu-LightItalic.ttf') format('truetype'),
    url('/fonts/Apercu-LightItalic.svg') format('svg'),
    url('/fonts/Apercu-LightItalic.otf') format('opentype');
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: 'AW Conqueror Didot';
  src: url('/fonts/AWConquerorFreDidot-Regular.eot');
  src: url('/fonts/AWConquerorFreDidot-Regular.woff2') format('woff2'),
    url('/fonts/AWConquerorFreDidot-Regular.woff') format('woff'),
    url('/fonts/AWConquerorFreDidot-Regular.ttf') format('truetype'),
    url('/fonts/AWConquerorFreDidot-Regular.svg') format('svg'),
    url('/fonts/AWConquerorFreDidot-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AWConquerorProDidot-Regular';
  src: url('/fonts/AWConquerorProDidot-Regular.eot');
  src: url('/fonts/AWConquerorProDidot-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/AWConquerorProDidot-Regular.otf') format('opentype'),
    url('/fonts/AWConquerorProDidot-Regular.svg') format('svg'),
    url('/fonts/AWConquerorProDidot-Regular.ttf') format('truetype'),
    url('/fonts/AWConquerorProDidot-Regular.woff') format('woff'),
    url('/fonts/AWConquerorProDidot-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AWConquerorProDidotL-Regular';
  src: url('/fonts/AWConquerorProDidotL-Regular.eot');
  src: url('/fonts/AWConquerorProDidotL-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/AWConquerorProDidotL-Regular.otf') format('opentype'),
    url('/fonts/AWConquerorProDidotL-Regular.svg') format('svg'),
    url('/fonts/AWConquerorProDidotL-Regular.ttf') format('truetype'),
    url('/fonts/AWConquerorProDidotL-Regular.woff') format('woff'),
    url('/fonts/AWConquerorProDidotL-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AWConquerorProDidotS-Regular';
  src: url('/fonts/AWConquerorProDidotS-Regular.eot');
  src: url('/fonts/AWConquerorProDidotS-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/AWConquerorProDidotS-Regular.otf') format('opentype'),
    url('/fonts/AWConquerorProDidotS-Regular.svg') format('svg'),
    url('/fonts/AWConquerorProDidotS-Regular.ttf') format('truetype'),
    url('/fonts/AWConquerorProDidotS-Regular.woff') format('woff'),
    url('/fonts/AWConquerorProDidotS-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Granjon';
  src: url('/fonts/GranjonLTStd.eot');
  src: url('/fonts/GranjonLTStd.woff2') format('woff2'),
    url('/fonts/GranjonLTStd.woff') format('woff'),
    url('/fonts/GranjonLTStd.ttf') format('truetype'),
    url('/fonts/GranjonLTStd.svg') format('svg'),
    url('/fonts/GranjonLTStd.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
